//
//
//
//
//
//
//
//
//
//
//

import baseCe from '~/node_modules/nuxt-typo3/lib/templates/components/content/mixins/baseCe'

export default {
  name: 'CeNewsPi1',
  extends: baseCe,
  props: {
    header: {
      type: String,
      required: false,
      default: ''
    },
    headerLayout: {
      type: Number,
      required: false,
      default: 0
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
